import React from 'react'

const Footer = () => {

  return (

    <footer>

      <div className="container">

          <div className="wrapper">

                <div className="text-center">

                  <p>
                    <small>
                        Marine Graphics Ltd. Part. © 2004 - 
                        <span className="primary-color">{new Date().getFullYear()}</span>. 
                        All Rights Reserved
                    </small>
                  </p>

                </div>

            </div>   

        </div>	

    </footer>

  )

}

export default Footer
