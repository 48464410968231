import React from 'react'
import Icons from '../layout/Icons'
import Loader from '../layout/Loader'

const PhpProjects = () => {

  return (

    <div className="container">

        <div className="wrapper">

            <a href="https://www.fishslates.com/" target="_blank" rel="noreferrer">
                <h2>Fish Slates</h2>
            </a> 

            <Icons icons={['dreamweaver', 'html', 'css', 'javascript', 'php', 'mysql' ]} /> 
    
            <p>My Signature Website...</p>                
            
            <div className="spinner">      

                <Loader />  
                <iframe src="https://fishslates.com/" title="description" />
            
            </div>
            
            <hr />

            <a href="http://www.deck-layouts.com/" target="_blank" rel="noreferrer">
                <h2>Deck Layouts</h2>
            </a> 

            <Icons icons={['dreamweaver', 'html', 'css', 'javascript', 'php', 'mysql' ]} /> 
            
            <p>My 'other' business...</p>            
        
            <div className="spinner">      

                <Loader />  
                <iframe src="https://deck-layouts.com/" title="description" />
            
            </div>
        
            <hr />

            <a href="https://www.westcoastdivers.com/" target="_blank" rel="noreferrer">
                <h2>West Coast Divers - Phuket</h2>
            </a> 

            <Icons icons={['dreamweaver', 'html', 'css', 'javascript', 'php', 'mysql' ]} /> 
            
            <p>A comprehensive PHP MySQL build with CMS...</p>
            
        
            <div className="spinner">      

                <Loader />  
                <iframe src="https://westcoastdivers.com/" title="description" />
            
            </div>  
        
            <hr />

            <a href="https://www.brite-online.com/" target="_blank" rel="noreferrer">
                <h2>Brite Digital Solutions - Phuket</h2>
            </a> 
                        
            <Icons icons={['dreamweaver', 'html', 'css', 'javascript', 'php', 'mysql' ]} /> 
            
            <p>My first build with PHP & MySQL with CMS... This has dual language and a custom payment gateway to KasikornBank Thailand</p>
            
        
            <div className="spinner">   
               
                <iframe src="https://www.brite-online.com/" title="description" />
            
            </div>  
        
            <hr />

        </div>

    </div>

  )

}

export default PhpProjects
