import React from 'react'

const Icons = ({ icons }) => {

    return (
        <div className="icons">

            {icons.length && icons.map((icon) => (
                <img 
                    key={icon}
                    src={`images/${icon}.png`} 
                    width="30" 
                    alt={icon}
                    title={icon}
                />
            ))}
            
        </div>  
    )

}

export default Icons