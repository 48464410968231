import React from 'react'
import { Helmet } from 'react-helmet'

const MetaData = ({ title }) => {

    return (

        <Helmet>

            <title>{`${title} - Nick's Portfolio`}</title>
            
        </Helmet>

    )

}

export default MetaData
