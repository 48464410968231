import React from 'react'

const FishSlates = () => {

  return (
    
    <div className="product anim">
        <div>
            <div className="back" ><img src="images/marine_life_slate.png"             width="300" height="580" alt="Slideshow" /></div>
            <div className="front"><img src="images/fish_slate.png"                    width="300" height="580" alt="Slideshow" /></div>            
        </div>
        <div className="zoom">
            <div className="back" ><img src="images/fish_identification_slate.png"     width="300" height="580" alt="Slideshow" /></div>
            <div className="front"><img src="images/reef_id_fish_slate.png"            width="300" height="580" alt="Slideshow" /></div>
        </div>
        <div>
            <div className="back" ><img src="images/coral_slate_of_southeast_asia.png" width="300" height="580" alt="Slideshow" /></div>
            <div className="front"><img src="images/reef_fish_id_card.png"             width="300" height="580" alt="Slideshow" /></div>
        </div>        
    </div>

  )
}

export default FishSlates