import React, { Fragment } from 'react'
import MetaData from './layout/MetaData'
import SpinngCube from './animations/SpinngCube'
import SvgDraw from './animations/SvgDraw'
import SvgPinball from './animations/SvgPinball'
import SvgTron from './animations/SvgTron'
import FishSlates from './animations/FishSlates'
import Icons from './layout/Icons'
import '../animation.css'

const Home = () => {

  return (

    <Fragment>

      <MetaData title="Home" />   

      <div className="container">

        <div className="wrapper">   

          <h2>Animating SVGs with CSS</h2>

          <Icons icons={['illustrator', 'vscode', 'html', 'css']} />   

          <p>We can draw any shape in Illustrator / Photoshop, 
            save as SVG, open in our IDE and drop it straight 
            into the HTML. This can then be styled / animated with CSS. 
            Here we are manipulating the offset of a dashed stroke over time.</p>

          <div className="stage preloaders">

            <div><SvgDraw /></div>
            <div><SvgPinball /></div>
            <div><SvgTron /></div>

          </div>    
          
          <hr />  

          <h2>CSS Animations</h2>

          <Icons icons={['dreamweaver', 'html', 'css', 'javascript']} />  

          <p>Manipulating CSS transformations over time and toggling 
            backface-visibility property. And there's a random lottery number
            generator.</p>  

          <div className="stage">
              
            <SpinngCube />  

          </div>

          <hr />  

          <div className="stage">              

            <FishSlates />

          </div>

          <hr />               

        </div>

      </div>

    </Fragment>

  )
  
}

export default Home
