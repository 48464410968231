import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const Loader = () => {

  return (

    <CircularProgress  className="loader" />

  )
  
}

export default Loader
