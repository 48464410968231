import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { Checkbox } from '@mui/material'

const SpinngCube = () => {

    const [ cubeVisible,      setCube             ] = useState(true)
    const [ backfacesVisible, setBackfacesVisible ] = useState(false)    

    const generate = () => {
        const shape = document.querySelector('#shape')
        shape.innerHTML = ''
        const numbers = [] // James' final solution checks for membership in the array
        while (numbers.length < 6) {
            const number = Math.floor((Math.random() * 49) + 1)
            if(numbers.indexOf(number) < 0){
                numbers.push(number)
                const node = document.createElement('LI')
                const textnode = document.createTextNode(number)
                node.appendChild(textnode)
                shape.appendChild(node)
            }
        }
    }

    return (

    <div id="stage">          
        
        <div className="text-right">
        
            <IconButton onClick={generate}>
                <AutorenewIcon className="icon" />
            </IconButton> 

            <IconButton onClick={() => setCube(!cubeVisible)}>
                <ViewInArIcon className="icon" />
            </IconButton> 
            
            <Checkbox 
                className="checkbox"
                onChange={() => setBackfacesVisible(!backfacesVisible)}
                checked={backfacesVisible ? false : true}
            />              
            
        </div>
        <ul 
            id="shape" 
            className={`
                ${!backfacesVisible ? 'backfaces' : ''}
                ${cubeVisible ? 'cube' : 'ring'}
            `}
        >
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
        </ul>

    </div>

    )

}

export default SpinngCube
