import React from 'react'
import Icons from '../layout/Icons'
import Loader from '../layout/Loader'

const WordpressProjects = () => {

  return (

    <div className="container">

        <div className="wrapper">

            <a href="https://adsiam.com/" target="_blank" rel="noreferrer">
                <h2>Wordpress Template</h2>
            </a> 
                        
            <Icons icons={['dreamweaver', 'html', 'css', 'javascript', 'wordpress' ]} /> 
            
            <p>A comprehensive WordPress template with custom theme, HTML emails, Advanced Custom Fields, Paypal payment gateweay & Custom Post / Taxononomy Types</p>
            

            <div className="spinner">      

                <Loader />
                <iframe src="https://adsiam.com/" title="description" />
            
            </div> 

            <hr />

            <a href="https://abstractartcanada.com/" target="_blank" rel="noreferrer">
                <h2>Abstract Art Canada</h2>
            </a> 

            <Icons icons={['dreamweaver', 'html', 'css', 'javascript', 'wordpress' ]} />                         

            
            <p>My largest project to date</p>
            

            <div className="spinner">      

                <Loader />
                <iframe src="https://abstractartcanada.com/" title="description" />
            
            </div> 

            <hr />

        </div>

    </div>    

  )

}

export default WordpressProjects
