import React from 'react'
import SquareIcon from '@mui/icons-material/Square'
import { Divider } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'

const CV = () => {

  return (

    <div className="container">

        <div className="wrapper">

            <div className="parent" style={{ color: "white" }}>

                <div 
                    style={{ 
                        background: "#24333f",
                        width: "60%"
                    }}
                >
                    <div className="wrapper">

                        <h5 style={{ textAlign: "right", color: "#999" }}>Curriculum Vitae</h5>

                        <h1>Nicholas G. Dittmer</h1>  

                        <h3>React JS and MERN Stack Developer</h3>   

                        <h4>Experience</h4>

                        <p><SquareIcon fontSize="small" /> &nbsp; 10 years of experience in developing UIs and server side solutions</p>                        

                        <p><SquareIcon fontSize="small" /> &nbsp; 10 years of experience in development, testing and deployment of APIs</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; 10 years of experience in developing and deploying database management systems</p>
                        
                        <p><SquareIcon fontSize="small" /> &nbsp; 1 year of experience in developing apps in React JS and MERN stack</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; 1 year of experience in debugging and performance improvement in React JS environment</p> 

                        <h4>Skills</h4>

                        <p><SquareIcon fontSize="small" /> &nbsp; <b>Languages</b> - JavaScript (ES5 + 6), PHP</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; <b>Client Scripting</b> - jQuery, React, Angular, Webpack</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; <b>Server Scripting</b> - Node.js (Express)</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; <b>Front End</b> - HTML5, CSS3, Sass</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; <b>Graphic Design</b> - Photoshop, Illustrator, CorelDraw, AutoCad</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; <b>Responsive Design</b> - Bootstrap, Flexbox, Grid, SVG</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; Knowledge of Linux, Windows, Node, Git / GitHub, Heroku, Firebase, Cloudinary & multiple browsers</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; Command over React's functional components, Virtual DOM, React Router Dom, Hooks, State and Redux / Thunk</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; User Interface design and development</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; Restful API development and deployment</p>

                        <p><SquareIcon fontSize="small" /> &nbsp; Continuous testing and integration of builds and releases</p>       
                        
                    </div>                    

                </div>

                <div 
                    style={{ 
                        background: "#212a33", 
                        width: "40%"
                    }}
                >
                    <div className="wrapper">

                        <div className=" logo d-flex justify-content-between align-tems-c">

                            <img src="images/siam-net-solutions.png" width="80" alt="logo" title="logo" />

                            <Divider orientation="vertical" flexItem />

                            <ul>
                                <li><small>Web Development</small></li>
                                <li><small>Western Management</small></li>
                                <li><small>Global Delivery</small></li>
                            </ul>

                        </div>    

                        <br /><br />

                        <div className="d-flex justify-content-c align-items-c relative">

                            <img src="images/chart.png" className="fluid" alt="chart" title="chart" />  

                            <div 
                                className="absolute text-center"
                                style={{ 
                                    top: "50%", 
                                    left: "50%", 
                                    transform: "translate(-50%,-50%", 
                                    width: "100%",
                                }}
                            
                            >

                                <h4>Siam Net Solutions</h4>  

                                <br />

                                <span style={{ border: "solid 2px white", padding: "5px" }}>
                                    ALL INCLUSIVE
                                </span>

                            </div>   

                        </div>   

                        <br /><br />

                        <h4 className="text-center">Rate Includes</h4>     

                        <br />

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "#0cf" }} />
                                    </td>
                                    <td>
                                        <small>Salary</small>
                                    </td>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "purple" }} />
                                    </td>
                                    <td>
                                        <small>Payroll taxes</small>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "orange" }} />
                                    </td>
                                    <td>
                                        <small>Office expenses</small>
                                    </td>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "turquoise" }} />
                                    </td>
                                    <td>
                                        <small>Sick leaves</small>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "violet" }} />
                                    </td>
                                    <td>
                                        <small>Vacation period</small>
                                    </td>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "magenta" }} />
                                    </td>
                                    <td>
                                        <small>Equipment and software</small>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "limeGreen" }} />
                                    </td>
                                    <td>
                                        <small>Employee retention</small>
                                    </td>
                                    <td>
                                        <CheckCircleOutlineIcon sx={{ color: "gold" }} />
                                    </td>
                                    <td>
                                        <small>Retention</small>
                                    </td>
                                </tr>
                            </tbody>                            
                        </table>       

                        <br /> <br />

                        <div className="text-center">

                            <img src="images/time.png"width="80" alt="chart" title="chart" />   

                            <br /><br />

                            <h2>TIMEFRAME</h2>       

                            <p>I need up to 3 weeks to begin work in earnest</p>  

                            <br />

                            <h2>CONTACT ME AT</h2>

                            <p>
                                <a href="mailto:info@fishslates.com" style={{ color: "gold" }}>info@fishslates.com </a>

                                to request more information or follow me on 
                                <a 
                                    href="https://github.com/nick622641/" 
                                    target="_blank" 
                                    rel="noreferrer"
                                    style={{ color: "gold" }}>
                                        &nbsp; GitHub 
                                </a>
                            </p>                  
                            
                        </div> 

                        <p>
                            "I'm a mule when it comes to tidying up... Simply put, testing is a disciplne and I have it. I hate that so many Big sites are broken and really believe I should have that person's job!'. Although I'm a (not so) humble Graphic Designer I soon realized that without FULL control of a system there really IS no frontend. Even to the point I learnt how to deploy this 'new' tech on my own servers. I still believe in, and have respect for, the customer, the visitor to our work..."
                        </p>

                        <p>
                            "<i><small>I have found Nick to be an extremely skilled developer who I can really count on.</small></i>"
                        </p>
                        <p className="text-right"><small><a href="mailto:skolsuper@gmail.com">James Keys</a></small></p>

                        <h4 style={{ marginTop: "200px" }}>Education</h4>

                        <p><DownloadDoneIcon fontSize="small" sx={{ color: "green" }} /> &nbsp; <b>Cambridge Regional College</b> <br /><br />
                            Chemistry<sup>(A)</sup>, Maths<sup>(B)</sup>, Biology<sup>(A)</sup>, Art<sup>(A)</sup>
                        </p>
                        <p><DownloadDoneIcon fontSize="small" sx={{ color: "green" }} /> &nbsp; <b>Wymondham College</b> <br /><br />
                            Sciences<sup>(A*A*)</sup>, 
                            Maths<sup>(B)</sup>, 
                            History<sup>(A)</sup>, 
                            Geography<sup>(A*)</sup>,
                            English<sup>(A*A*A)</sup>,
                            German<sup>(A)</sup>,
                            Electronics<sup>(A)</sup>
                        </p>
                    
                    </div>

                </div>

            </div>            

        </div>

    </div>   

  )
  
}

export default CV