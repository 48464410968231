import { Fragment                   } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useTransition, animated    } from 'react-spring'

import Header                         from './components/layout/Header'
import Footer                         from './components/layout/Footer'
import ScrollToTop                    from './components/layout/ScrollToTop'

import Home                           from './components/Home'
import Angular                        from './components/pages/Angular'
import PhpProjects                    from './components/pages/PhpProjects'
import ReactProjects                  from './components/pages/ReactProjects'
import WordpressProjects              from './components/pages/WordpressProjects'
import CV from './components/pages/CV'

function App() {

  const location = useLocation()
  const transitions = useTransition( location, {
    from:  { opacity: 0,   transform: "translate( 100%, 0%)" },
    enter: { opacity: 1,   transform: "translate(   0%, 0%)" },
    leave: { opacity: 0.5, transform: "translate(-100%, 0%)", position: "absolute", width: "100%", top: "150px" }
  })

  return (

    <Fragment>

      <Header />   

      {transitions((props, item) => (  

        <animated.div style={props} className="main">

          <ScrollToTop />

          <Routes location={item}>

            <Route path="/"                 element={<Home              />} />
            <Route path="/angular"          element={<Angular           />} />
            <Route path="/react"            element={<ReactProjects     />} />
            <Route path="/php"              element={<PhpProjects       />} />
            <Route path="/wordpress"        element={<WordpressProjects />} />    
            <Route path="/curriculum-vitae" element={<CV                />} />

          </Routes>

        </animated.div>     

      ))}

      <Footer />   

    </Fragment>

  )

}

export default App
