import React from 'react'
import Icons from '../layout/Icons'
import Loader from '../layout/Loader'

const Angular = () => {

  return (

    <div className="container">
        
        <div className="wrapper">  
      
          <a href="https://www.siamly.com/" target="_blank" rel="noreferrer">
              <h2>Covid Tracker</h2>
          </a>

          <Icons icons={[ 'vscode', 'angular', 'json']} /> 
         
          <p>A simple app using only Angular and JSON feeds for the data. This project also explores the Angular Material Table Component, with built in search, sorting, pagination, downloads and more. I've also used this space to demonstrate page transitions (as a concept I really like) and one of the best carousel sliders I could find. Basically using all inhouse stuff here... no Bootstrap or jQuery in sight....</p>
        
          <div className="spinner">      

            <Loader /> 
            <iframe src="https://www.siamly.com/" title="description" />
        
          </div>   
          
          <hr />            

        </div>

    </div>

  )

}

export default Angular
