import React from 'react'
import Icons from '../layout/Icons'
import Loader from '../layout/Loader'

const ReactProjects = () => {

  return (

    <div className="container">

      <div className="wrapper">

        <a href="http://149.28.155.92/" target="_blank"  rel="noreferrer">
            <h2>Nicotene Calculator</h2>
        </a>
  
        <Icons icons={['vscode', 'express', 'react', 'node', 'github']} />

        <p>A funtioning calculator made using MUI Material</p>      
        
        <div className="spinner">      

          <Loader />  
          <iframe src="http://149.28.155.92/" title="description" />
      
        </div>   

        <hr />

        <a href="https://shopittemplate.herokuapp.com/" target="_blank"  rel="noreferrer">
            <h2>ShopIT E-Commerce site</h2>
        </a>

        <Icons icons={['vscode', 'mongodb', 'express', 'react', 'node', 'github', 'heroku', 'cloudinary']} />
        
        <p>E-commerce site using MERN stack (fully functional)</p>            
        
        <div className="spinner">      

          <Loader />  
          <iframe src="https://shopittemplate.herokuapp.com/" title="description" />
      
        </div>   

        <hr />

        <a href="https://ecommerce-sanity-stripe-pink.vercel.app/" target="_blank"  rel="noreferrer">
            <h2>JSM Headphones</h2>
        </a>

        <Icons icons={['vscode', 'react', 'sanity', 'stripe', 'vercel']} />        
        
        <p>E-Commerce site built with Sanity and Stripe</p>            
        
        <div className="spinner">      

          <Loader />  
          <iframe src="https://ecommerce-sanity-stripe-pink.vercel.app/" title="description" />
      
        </div>   

        <hr />

        <a href="https://cannabisshop.netlify.app/" target="_blank"  rel="noreferrer">
            <h2>W-Shop</h2>
        </a>

        <Icons icons={['vscode', 'react', 'commerce', 'stripe', 'netlify']} />       
        
        <p>E-Commerce site built with Commerce.js backend & Stripe Payment</p>            
        
        <div className="spinner">      

          <Loader />  
          <iframe src="https://cannabisshop.netlify.app/" title="description" />
      
        </div>   

        <hr />

        <a href="https://syncfusion-tailwind-dashboard.netlify.app/" target="_blank"  rel="noreferrer">
            <h2>SyncFusion Dashboard</h2>
        </a>

        <Icons icons={['vscode', 'react', 'tailwind', 'syncfusion', 'netlify']} />        
        
        <p>Exploring Data Types and Presentation using Tailwind and SyncFusion</p>            
        
        <div className="spinner">      

          <Loader />  
          <iframe src="https://syncfusion-tailwind-dashboard.netlify.app/" title="description" />
      
        </div>   

        <hr />

        <a href="https://abstractartcanada.herokuapp.com/" target="_blank"  rel="noreferrer">
            <h2>Abstract Art Canada</h2>
        </a>

        <Icons icons={['vscode', 'mongodb', 'express', 'react', 'node', 'github', 'heroku', 'cloudinary']} />        
        
        <p>This is a special one for me. It's a clone of an existing project I've had the privilige to be a part of... While that monstrocity was built in Wordpress this was done in React using MERN stack to power it. Theory is one thing. Trying to build something to a particular set of specifications Really tests one's ability to 'control' the code... This took a few weeks going on months as it was my first successful React build outside of a tutorial...</p>            
        
        <div className="spinner">      

          <Loader />  
          <iframe src="https://abstractartcanada.herokuapp.com/" title="description" />
      
        </div>   

        <hr />

        <a href="https://arttemplate.herokuapp.com/" target="_blank"  rel="noreferrer">
            <h2>Art Gallery Template</h2>
        </a>
        
        <Icons icons={['vscode', 'mongodb', 'express', 'react', 'node', 'github', 'heroku', 'cloudinary']} /> 
        
        <p>A stripped down version of the above...</p>            
        
        <div className="spinner">      

          <Loader />  
          <iframe src="https://arttemplate.herokuapp.com/" title="description" />
      
        </div>   

        <hr />

      </div>

    </div>

  )

}

export default ReactProjects
