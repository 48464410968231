import React from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import GitHubIcon from '@mui/icons-material/GitHub'

const Header = () => {

  return (

    <header>

      <div className="container wrapper-x">

        <div className="parent align-tems-c">

          <Link to="/">
            <img src="images/logo.png" width="30" alt="Marine Graphics" />
          </Link>

          <h2 className="parent align-tems-c">
              Nick's &nbsp;
              <img className="round" src="images/me.jpg" width="100" height="100" alt="me" />
              &nbsp; Projects  
          </h2>

          <a href="https://github.com/nick622641/" title="GitHub" target="_blank" rel="noreferrer">
            <IconButton>
              <GitHubIcon fontSize="large" className="icon" />
            </IconButton>    
          </a>             

        </div>  

        <nav>
          <ul className="parent">
            <li>
              <img src="images/react.png" width="20" alt="React.js" title="React.js" />
              &nbsp; <Link to="/react">React</Link>
            </li>  
            <li>
              <img src="images/angular.png" width="20" alt="Angular" title="Angular" />
              &nbsp; <Link to="/angular">Angular</Link>
            </li>  
            <li>
              <img src="images/php.png" width="20" alt="PHP" title="PHP" /> 
              &nbsp; <Link to="/php">PHP</Link>
            </li>  
            <li>
              <img src="images/wordpress.png" width="20" alt="WordPress" title="WordPress" /> 
              &nbsp; <Link to="/wordpress">WordPress</Link>
            </li>  
            <li>
              <img src="images/cv.png" width="20" alt="cv" title="cv" /> 
              &nbsp; <Link to="/curriculum-vitae">CV</Link>
            </li>  
          </ul>  
        </nav>       

      </div>  

    </header>

  )

}

export default Header
